/*
 * @description: Don't description
 * @Author: RoyalCannin
 * @Date: 2022-05-15 19:22:54
 * @FilePath: \smart-reco-portal\src\main.js
 * @LastEditTime: 2022-12-04 17:42:57
 */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/tailwind.css'
import loadingViewerVue from 'loading-viewer-vue'
import { Dialog } from 'vant'
import { Icon } from 'vant'
createApp(App).use(router).use(store).use(Vant).use(loadingViewerVue,{mode:'2'}).use(Dialog).use(Icon).mount('#app')


Date.prototype.Format = function (fmt) {
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'S': this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
    return fmt
}