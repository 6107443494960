<!--
 * @description: Don't description
 * @Author: RoyalCannin
 * @Date: 2022-06-05 21:42:34
 * @FilePath: \reco-uif:\source code\EVET\smart-reco-h5\src\components\SelectPet.vue
 * @LastEditTime: 2022-08-08 22:40:57
-->
<template>
<div class="bg-white dark:bg-white">
    <input type="checkbox" :checked="true" id="my-modal-3" class="modal-toggle" />
    <div class="modal bg-white dark:bg-white">
        <div v-if="petsList.length>0" class="modal-box relative rounded-md bg-white dark:bg-white">
            <!-- <button @click="close" class="btn btn-sm btn-circle absolute right-2 border-none top-2 bg-white text-black">✕</button> -->
            <van-row style="font-size: 24px;color: #E62122;display: flex;align-items: center;justify-content: center;" class="mb-4">
                已匹配到多只宠物档案
            </van-row>
            <van-row>
                <van-col span="22">
                    <van-radio-group v-model="petId">
                        <van-divider/>
                        <div v-for="pet in petsList" v-bind:key="pet">
                            <van-radio :name="pet" icon-size="18px" checked-color="#ee0a24">
                                <span style="font-size:14px">{{pet.petName}}—{{pet.petTypeName}}—{{pet.isSterilization?'已绝育':'未绝育'}}</span>
                            </van-radio>
                            <van-divider />
                        </div>
                    </van-radio-group>
                </van-col>
            </van-row>
            <!-- <van-col offset="2" span="10">
                    <button @click="close" class="btn border-none" style="background: #E62122;border-radius: 6px;">确认选择</button>
                </van-col> -->

            <van-row style="display: flex;align-items: center;justify-content: center;height:80px">
                <van-col offset="0" span="10" class="mt-4 mb-12">
                    <button @click="close" class="btn btn-wide w-full border-none" style="background: #d4d6db;border-radius: 6px;">取消</button>
                </van-col>
                <van-col offset="1" span="10" class="mt-4 mb-12">
                    <button @click="confirm" class="btn btn-wide w-full border-none" style="background: #E62122;border-radius: 6px;">确认</button>
                </van-col>
            </van-row>
        </div>
        <div v-else class="modal-box relative rounded-md bg-white dark:bg-white">
            <van-row style="font-size: 24px;color: #E62122;display: flex;margin-top:50%;align-items: center;justify-content: center;" class="mb-4">
                未匹配到宠物档案
            </van-row>
            <van-row style="font-size: 16px;display: flex;margin-bottom:50%;align-items: center;justify-content: center;" class="mb-4">
                请返回重新创建
            </van-row>

            <van-row style="display: flex;align-items: center;justify-content: center;height:80px">
                <van-col offset="0" span="10" class="mt-4 mb-12">
                    <button @click="close" class="btn btn-wide w-full border-none" style="background: #d4d6db;border-radius: 6px;">取消</button>
                </van-col>
            </van-row>
        </div>
    </div>
</div>
</template>

<script>
import {
    Toast
} from 'vant'
import {
    ref,
    reactive
} from "vue";
import {
    getStore
} from '@/utils/store';
export default {
    name: "SelectPet",
    props: {
        species: {
            type: Object,
            require: true
        }
    },
    emits: ['close'],
    setup(_, {
        emit
    }) {
        const selectPathologiesNameList = reactive(getStore({
            name: 'selectPathologieNames'
        }) || []);
        const petsList = ref([]);
        const breedTypeList = ref([]);
        const petId = ref({})
        const close = () => emit('close');
        // const  = () => 
        const show = ref(true);
        return {
            show,
            petId,
            petsList,
            selectPathologiesNameList,
            breedTypeList,
            close
        }
    },
    data() {
        return {
            activeNames: []
        }
    },
    mounted() {
        this.getOperLogInfoByContactWay();
    },
    methods: {
        confirm(){
            console.log("this.petId",this.petId.petName)
            if(this.petId.petName){
                this.$emit('close', this.petId);
            }else{
                this.$dialog.alert({message: "请选择宠物信息！"})
            }
        },
        getOperLogInfoByContactWay() {
            const toast = Toast.loading({
                message: '宠物档案加载中...', //加载的时候显示的文字
                duration: 0, //展示时长(ms)，值为 0 时，toast 不会消失
                overlay: true, //是否显示遮罩层
                forbidClick: true //是否禁止背景点击
            });
            fetch(process.env.VUE_APP_BASE_API+"smartreco/api/getPetInfoByContactWay?contactWay=" + getStore({
                    name: 'userPhoneNumber'
                }), )
                .then(response => response.text())
                .then(result => {
                    toast.clear()
                    this.globalData = JSON.parse(result);
                    if (this.globalData.code) {
                        this.petsList = this.globalData.data;
                        this.petsList.forEach(item => {
                            this.getDictLabelByType(item);
                        })
                        console.log(this.petsList)
                    } else {
                        console.log(this.globalData.code)
                    }
                })
                .catch(error => console.log('error', error));
        },
        getDictLabelByType(item) {
            fetch(process.env.VUE_APP_BASE_API+"smartreco/api/getDictLabelByType?dictType=varieties_pet" + '&dictValue=' + item.varietiesPet)
                .then(response => response.text())
                .then(result => {
                    this.globalData = JSON.parse(result);
                    if (this.globalData.code) {
                        item.petTypeName = this.globalData.msg
                    } else {
                        console.log(this.globalData.code)
                    }
                })
                .catch(error => console.log('error', error));
        },

    }
}
</script>

<style lang="less" scoped>
.mask {
    background-color: lightgrey;
    z-index: 10;
}

.category {
    font-size: 16px;
    color: #808285;
}

:deep(.van-icon__image) {
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
    // margin-top: 15%;
    font-size: 30px;
    border: 2.6px solid #D4D6DB;
    border-radius: 30px;
}

.rounded-rectangle-27-copy-2-4 {
    background-color: #ffffff;
    float: left;
    padding: 10px;
    margin: 0px 10px 10px 0px;
    border-radius: var(--ms-radius, 4px);
}

.rounded-rectangle-27-copy-2-4-active {
    background-color: #ffffff;
    float: left;
    padding: 10px;
    margin: 0px 10px 10px 0px;
    border-radius: var(--ms-radius, 4px);
}

.van-cell {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    overflow: hidden;
    color: var(--van-cell-text-color);
    font-size: var(--van-cell-font-size);
    // line-height: var(--van-cell-line-height);
    background: var(--van-cell-background-color);
}

// :deep(.modal-box) {
//     top:5%;
//     max-height: calc(100vh - 5em);
//     --tw-bg-opacity: 1;
//     background-color: hsl(var(--b1) / var(--tw-bg-opacity));
//     padding: 1rem;
//     transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
//     transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
//     transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
//     transition-duration: 200ms;
//     transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//     max-width: 32rem;
//     --tw-scale-x: .9;
//     --tw-scale-y: .9;
//     transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
//     border-top-left-radius: var(--rounded-box, 1rem);
//     border-top-right-radius: var(--rounded-box, 1rem);
//     border-bottom-left-radius: var(--rounded-box, 1rem);
//     border-bottom-right-radius: var(--rounded-box, 1rem);
//     box-shadow: 0 25px 50px -12px rgb(0 0 0 / 25%);
//     overflow-y: auto;
//     overscroll-behavior: contain;
// }
</style>
