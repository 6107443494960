<template>
  <div class="bg-white dark:bg-white home text-2xl" style="color: #333333">
    <page-header></page-header>
    <van-row
      style="font-size: 24px; color: #e62122; font-weight: bold"
      class="mb-4"
    >
      皇家临床营养方案
    </van-row>
    <van-row class="mb-2">宠主手机号</van-row>
    <van-row>
      <van-field
        class="input-item"
        name="telephone"
        required
        type="number"
        maxlength="11"
        v-model="telephone"
        placeholder="请输入手机号"
      >
        <template #button>
          <van-button size="small" @click="getPets" color="red" type="primary"
            >匹配档案</van-button
          >
        </template>
      </van-field>
    </van-row>
    <van-row>
      <van-col span="12">
        <van-row class="mb-2">宠物类型</van-row>
        <van-row>
          <van-col span="12">
            <van-button
              type=""
              v-model="species"
              @click="species = 'dog'"
              :class="[species == 'dog' ? 'text-white bg-theme ' : ' border']"
              >狗</van-button
            >
          </van-col>
          <van-col span="12">
            <van-button
              type="plain"
              v-model="species"
              @click="species = 'cat'"
              :class="[species == 'cat' ? 'text-white bg-theme ' : ' border']"
              >猫</van-button
            >
          </van-col>
        </van-row>
      </van-col>
      <van-col span="12">
        <van-row class="mb-2">宠物名称</van-row>
        <van-field
          class="input-item"
          v-model="petName"
          placeholder="请输入宠物名称"
        />
      </van-col>
    </van-row>
    <van-row class="mb-2">品种</van-row>
    <Multiselect
      :searchable="true"
      :close-on-select="true"
      v-model="breedType"
      :options="breedTypeList"
    />

    <van-row class="mb-2"> 生日 </van-row>
    <van-row>
      <van-col span="18">
        <van-field
          class="input-item"
          readonly
          :disabled="!dateOfBirthDis"
          v-model="dateOfBirth"
          @click="show = true"
        />
        <van-popup position="bottom" v-model:show="show" v-if="dateOfBirthDis">
          <van-datetime-picker
            type="date"
            v-model="currentDate"
            title="选择生日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
          />
        </van-popup>
      </van-col>
      <van-col
        span="6"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        "
      >
        {{ lifeStageName }}
      </van-col>
    </van-row>

    <van-row class="mt-4 mb-2"> 运动量 </van-row>

    <Multiselect
      :searchable="true"
      :close-on-select="true"
      :disabled="lifeStageDis"
      v-model="lifeStage"
      :options="values"
    />
    <!-- <van-dropdown-menu>
        <van-dropdown-item v-model="lifeStage" :disabled="lifeStageDis" :options="values" />
    </van-dropdown-menu> -->
    <!-- <van-field class="input-item" v-model="value" placeholder="" /> -->
    <!-- <van-calendar v-model:show="show" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" /> -->

    <van-row>
      <van-col span="12">
        <van-row class="mb-2">性别</van-row>
        <van-row>
          <van-col span="12">
            <van-button
              type=""
              v-model="sex"
              @click="sex = true"
              :class="[sex ? 'text-white bg-theme ' : ' border']"
              >公</van-button
            >
          </van-col>
          <van-col span="12">
            <van-button
              type="plain"
              v-model="sex"
              @click="sex = false"
              :class="[!sex ? 'text-white bg-theme ' : ' border']"
              >母</van-button
            >
          </van-col>
        </van-row>
      </van-col>
      <van-col span="12">
        <van-row class="mb-2">是否绝育</van-row>
        <van-row>
          <van-col span="12">
            <van-button
              type=""
              v-model="neutered"
              @click="neutered = true"
              :class="[neutered ? 'text-white bg-theme ' : ' border']"
              >是</van-button
            >
          </van-col>
          <van-col span="12">
            <van-button
              type="plain"
              v-model="neutered"
              @click="neutered = false"
              :class="[!neutered ? 'text-white bg-theme ' : ' border']"
              >否</van-button
            >
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row
      v-if="!reproductionStatusDis && !neutered"
      class="mb-2"
      style="margin-top: 10px"
      >繁殖状态</van-row
    >
    <van-dropdown-menu v-if="!reproductionStatusDis && !neutered">
      <van-dropdown-item
        v-model="reproductionStatus"
        :disabled="reproductionStatusDis"
        :options="reproductionStatusList"
      />
    </van-dropdown-menu>

    <van-row class="mb-2 mt-4"> 体况评分BCS </van-row>

    <div class="bcs-input-item w-full">
      <div
        class="block btn-group bg-theme w-full text-center py-2"
        style="border-radius: 4px"
      >
        <button
          class="border-none h-12 text-theme bg-white"
          style="width: 10%"
          v-for="i in 9"
          :key="i"
          :class="{ 'bg-theme': i === BCS }"
          :style="{ color: i === BCS ? 'white' : '' }"
          @click="setBCS(i)"
        >
          {{ i }}
        </button>
      </div>
    </div>

    <van-row>
      <van-col span="10">
        <van-row class="mb-2">当前体重</van-row>
        <van-field
          class="input-item"
          v-model="currentWeight"
          type="number"
          placeholder="当前"
        >
          <template #right-icon>
            <span class="px-4">kg</span>
          </template>
        </van-field>
      </van-col>
      <van-col
        offset="4"
        span="10"
        v-if="(data.BCS > 3 && adultHopeWeightDis) || hopeWeightDis"
      >
        <van-row class="mb-2">目标体重</van-row>
        <van-field
          class="input-item"
          :disabled="hopeWeightDis"
          v-model="hopeWeight"
          readonly
          placeholder="目标体重"
        >
          <template #right-icon>
            <span class="px-4">kg</span>
          </template>
        </van-field>
      </van-col>
    </van-row>
    <van-row class="mb-2"> 疾病类型&易感性 </van-row>

    <van-row>
      <van-tag
        v-if="bcsPathologies"
        class="rounded-rectangle-27-copy-2-4-active"
        color="#E62122"
        plain
        >{{ bcsPathologiesName }}</van-tag
      >
      <div v-for="item in selectPathologieNames" v-bind:key="item.dictValue">
        <van-tag
          closeable
          v-if="item.listClass === '0'"
          class="rounded-rectangle-27-copy-2-4-active"
          @close="removePathologie(item)"
          color="#E62122"
          plain
          >{{ item.dictLabel }}</van-tag
        >
      </div>
      <div v-for="item in selectPathologieNames" v-bind:key="item.dictValue">
        <van-tag
          closeable
          v-if="item.listClass === '1'"
          class="rounded-rectangle-27-copy-2-4-active"
          @close="removePathologie(item)"
          color="#7e89a9"
          plain
          >{{ item.dictLabel }}</van-tag
        >
      </div>
      <div class="rounded-rectangle-27-copy" @click="choosePathologies()">
        <img src="../assets/add.png" class="add" />
      </div>
    </van-row>
    <van-row>
      <van-col offset="" span="26" class="flex">
        <van-checkbox
          v-model="checked"
          checked-color="#ee0a24"
          shape="square"
          style="vertical-align: middle; font-size: 14px"
        >
          同意保存宠物数据在兽医应用程序中
        </van-checkbox>
      </van-col>
    </van-row>
    <van-divider
      :style="{ color: '#E62122', borderColor: '#E62122', padding: '0 16px' }"
    >
      选择产品
    </van-divider>
    <van-row> 智能推荐 </van-row>
    <van-row style="position: relative">
      <van-image width="100%" :src="ad1" />
      <button
        :disabled="flag"
        class="bg-white px-2"
        style="
          position: absolute;
          height: 32px;
          top: calc(50% - 16px);
          color: #e62122;
          border-radius: 6px;
          font-size: 14px;
          right: 3%;
        "
        @click="submitForm('step', 0)"
      >
        立即匹配
      </button>
    </van-row>

    <van-row>自行选择</van-row>
    <van-row style="position: relative">
      <van-image width="100%" :src="ad2" />
      <button
        :disabled="flag"
        class="text-white px-2"
        style="
          position: absolute;
          height: 32px;
          top: calc(50% - 16px);
          right: 3%;
          background: #292a2d;
          border-radius: 6px;
          font-size: 14px;
        "
        @click="submitForm('StepView2', 1)"
      >
        立即匹配
      </button>
    </van-row>

    <van-row class="mt-4">
      <van-col offset="" span="26" class="">
        <van-checkbox
          v-model="checked2"
          checked-color="#ee0a24"
          shape="square"
          style="vertical-align: middle; font-size: 14px"
        >
          本站自动生成的任何营养方案推荐仅供参考，不做诊断和处方的根据
        </van-checkbox>
      </van-col>
      <!-- <van-col span="22" class="text-base" style="color: #8F939A;"></van-col> -->
    </van-row>
  </div>

  <select-pathologies
    v-if="showPathologies"
    :species="species"
    @close="closePathologies()"
  ></select-pathologies>
  <select-pet v-if="showPets" @close="closePets($event)"></select-pet>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import { Toast } from "vant";
import { getStore, setStore, removeStore } from "@/utils/store";
import ad1 from "../assets/ad1.svg";
import ad2 from "../assets/ad2.svg";
import Multiselect from "@vueform/multiselect";
import PageHeader from "@/components/PageHeader";
import SelectPathologies from "@/components/SelectPathologies";
import SelectPet from "@/components/SelectPet";
export default {
  name: "HomeView",
  components: {
    PageHeader,
    Multiselect,
    SelectPathologies,
    SelectPet,
  },
  data() {
    return {
      optionValue: null,
      options: ["Batman", "Robin", "Joker"],
      wcyPersonId: "",
      wcyCompanyId: "",
    };
  },
  setup() {
    const flag = ref(false);
    const globalData = {};
    const telephone = ref(
      getStore({
        name: "userPhoneNumber",
      })
    );
    const petName = ref(
      getStore({
        name: "petName",
      })
    );
    const lifeStage = ref(
      getStore({
        name: "lifeStage",
      }) || ""
    );
    const lifeStageDis = ref(true);
    const hopeWeightDis = ref(true);
    const adultHopeWeightDis = ref(true);

    const dateOfBirthDis = ref(false);
    const reproductionStatusDis = ref(true);
    const reproductionStatus = ref(
      getStore({
        name: "reproductionStatus",
      }) || ""
    );
    const reproductionStatusList = [
      {
        text: "妊娠",
        value: "gestation",
      },
      {
        text: "哺乳",
        value: "lactation",
      },
      {
        text: "无",
        value: "",
      },
    ];
    const breedName = ref(
      getStore({
        name: "breedName",
      })
    );
    const breedType = ref(
      getStore({
        name: "breedType",
      }) || ""
    );
    const breedTypeList = ref();
    const breedTypeDogList = ref();
    const breedTypeCatList = ref();
    const checked = ref(
      getStore({
        name: "checked",
      }) || false
    );
    const checked2 = ref(
      getStore({
        name: "checked2",
      }) || false
    );
    const species = ref(
      getStore({
        name: "species",
      }) || ""
    );
    const neutered = ref(
      getStore({
        name: "neutered",
      }) || false
    );
    const sex = ref();
    const currentWeight = ref(
      getStore({
        name: "weight",
      })
    );
    const hopeWeight = ref(
      getStore({
        name: "hopeWeight",
      })
    );
    const bcsPathologies = ref(
      getStore({
        name: "bcsPathologies",
      }) || ""
    );
    const bcsPathologiesName = ref(
      getStore({
        name: "bcsPathologiesName",
      }) || ""
    );
    const pathologiesNames = [];
    const pathologies = ref(
      getStore({
        name: "pathologies",
      }) || []
    );
    const selectPathologieNames = ref(
      getStore({
        name: "selectPathologieNames",
      }) || []
    );

    const pathologiesList = [];
    const sensitivities = ref("0");
    const sensitivityNames = [];
    const sensitivity = ref(
      getStore({
        name: "sensitivity",
      }) || []
    );
    const sensitivitiesList = ref();
    const lifeStages = ref([]);
    const lifeStageName = ref("");

    const values = ref([]);

    const d = new Date();
    const dateOfBirth = ref(
      getStore({
        name: "dateOfBirth",
      })
    );
    const currentDate = ref(
      new Date(1900 + d.getYear(), d.getMonth(), d.getDate())
    );
    const show = ref(false);

    const formatDate = (date) =>
      `${1900 + date.getYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const onConfirm = (value) => {
      show.value = false;
      dateOfBirth.value = formatDate(value);
    };
    const showBackPets = ref(false);
    const data = reactive({
      BCS:
        getStore({
          name: "bcs",
        }) || 5,
      active: 1,
      showPathologies: false,
      showPets: false,
    });

    return {
      ...toRefs(data),
      flag,
      data,
      telephone,
      petName,
      species,
      neutered,
      sex,
      globalData,
      currentDate,
      dateOfBirth,
      currentWeight,
      hopeWeight,
      show,
      showBackPets,
      onConfirm,
      dateOfBirthDis,
      reproductionStatusList,
      reproductionStatusDis,
      reproductionStatus,
      breedName,
      breedType,
      breedTypeDogList,
      breedTypeCatList,
      breedTypeList,
      pathologies,
      selectPathologieNames,
      pathologiesNames,
      bcsPathologiesName,
      bcsPathologies,
      pathologiesList,
      sensitivityNames,
      sensitivity,
      sensitivities,
      sensitivitiesList,
      adultHopeWeightDis,
      lifeStageDis,
      lifeStages,
      lifeStageName,
      hopeWeightDis,
      values,
      lifeStage,
      checked,
      checked2,
      minDate: new Date(1900 + d.getYear() - 20, d.getMonth(), d.getDate()),
      maxDate: new Date(),
      ad1,
      ad2,
    };
  },
  created() {
    document.documentElement.style.setProperty("--bg-color", "white");
    document.documentElement.style.setProperty("--a-color", "#292a2d");

    setStore({
      name: "shopId",
      content:
        this.$route.query.shopId ||
        getStore({
          name: "shopId",
        }),
    });
    if (
      !getStore({ name: "shopId" }) ||
      getStore({ name: "shopId" }) === "undefined"
    ) {
      this.$dialog
        .alert({
          message: "获取门店编码失败，请勿继续操作，联系系统管理员",
        })
        .then(() => {
          //点击确认按钮后的调用
          // this.flag = true;
        });
      return;
    }

    if (this.$route.query.petName) {
      this.petName = this.$route.query.petName;
      setStore({ name: "petName", content: this.$route.query.petName });
    }
    //页面数据缓存
    if (this.$route.query.telephone) {
      this.telephone = this.$route.query.telephone;
      setStore({
        name: "userPhoneNumber",
        content: this.$route.query.telephone,
      });
    }
    setStore({ name: "species", content: this.$route.query.species });
    if (this.$route.query.species) {
      this.species = this.$route.query.species === "0" ? "dog" : "cat";
    }
    // setStore({ name: "petName", content: this.$route.query.petName });
    // if (this.$route.query.petName) {
    //   this.petName = this.$route.query.petName;
    // }
    // setStore({name: 'breedType',content: this.$route.query.breedType})
    // if(this.$route.query.breedType){
    //     this.breedType = this.$route.query.breedType;
    // }
    if (this.$route.query.dateOfBirth) {
      setStore({ name: "dateOfBirth", content: this.$route.query.dateOfBirth });
      this.dateOfBirth = this.$route.query.dateOfBirth;
    }
    setStore({ name: "lifeStage", content: this.$route.query.lifeStage });
    if (this.$route.query.lifeStage) {
      this.lifeStage = this.$route.query.lifeStage;
    }
    if (this.$route.query.sex) {
      console.log("this.$route.query.sex", this.$route.query.sex);
      this.sex = this.$route.query.sex === "0" ? true : false;
      setStore({ name: "sex", content: this.sex });
      setStore({
        name: "genderCode",
        content: this.$route.query.sex === "0" ? "male" : "female",
      });
    }
    setStore({ name: "neutered", content: this.$route.query.neutered });
    if (this.$route.query.neutered) {
      this.neutered = this.$route.query.neutered > 0 ? true : false;
    }
    setStore({
      name: "currentWeight",
      content: this.$route.query.currentWeight,
    });
    if (this.$route.query.currentWeight) {
      this.currentWeight = this.$route.query.currentWeight;
      this.hopeWeight = this.$route.query.currentWeight;
    }
    setStore({ name: "hospital", content: this.$route.query.hospital });
    setStore({
      name: "hospitalPhone",
      content: this.$route.query.hospitalPhone,
    });
    setStore({ name: "province", content: this.$route.query.province });
    setStore({ name: "city", content: this.$route.query.city });
    setStore({ name: "district", content: this.$route.query.district });
    setStore({
      name: "dryProductMathWet",
      content: false,
    });
    if (
      getStore({
        name: "breedCode",
      })
    ) {
      this.dateOfBirthDis = true;
    }
    // this.queryHospital()

    this.getDictByTypeAndDogPet("varieties_pet", "dog");
    this.getDictByTypeAndCatPet("varieties_pet", "cat");
  },
  watch: {
    currentWeight(val) {
      setStore({
        name: "weight",
        content: val,
      });
      if (val) {
        this.adultHopeWeightDis = true;
      } else {
        this.adultHopeWeightDis = false;
      }
      this.setBCS(this.data.BCS);
    },
    dateOfBirth(newDate) {
      setStore({
        name: "dateOfBirth",
        content: newDate,
      });
      this.queryPetProfile(newDate);
    },
    petName(val) {
      setStore({
        name: "petName",
        content: val,
      });
    },
    telephone(val) {
      setStore({
        name: "userPhoneNumber",
        content: val,
      });
    },
    sex(val) {
      setStore({
        name: "sex",
        content: val,
      });
      setStore({
        name: "genderCode",
        content: val ? "female" : "male",
      });
      if (this.sex) {
        this.reproductionStatusDis = true;
      } else {
        this.reproductionStatusDis = false;
      }

      this.queryPetProfile(this.dateOfBirth);
    },
    neutered(val) {
      setStore({
        name: "neutered",
        content: val,
      });
      if (val) {
        setStore({
          name: "neuteredName",
          content: "已绝育",
        });
      }
    },
    species(val) {
      if (val === "cat") {
        this.breedTypeList = this.breedTypeCatList;
      } else {
        this.breedTypeList = this.breedTypeDogList;
      }
      setStore({
        name: "petBreed",
        content: val == "cat" ? 1 : 0,
      });
      setStore({
        name: "speciesCode",
        content: val,
      });
      setStore({
        name: "species",
        content: val,
      });
      console.log(this.showBackPets);
      if (!this.showBackPets) {
        this.breedType = "";
        this.hopeWeight = "";
        this.dateOfBirth = "";
        this.lifeStageName = "";
        this.currentWeight = "";
        this.reproductionStatus = "";
        this.data.BCS = 5;
        this.pathologies = [];
        this.lifeStage = "";
        this.lifeStageDis = true;
        this.show = false;
        this.dateOfBirthDis = false;
      }
      this.showBackPets = false;
      console.log(this.showBackPets);
      this.getDictByTypeAndPet(
        "varieties_pet,disease_type,susceptibility,life_stage,amount_of_exercise",
        this.species
      );
    },
    lifeStage(val) {
      setStore({
        name: "lifeStage",
        content: val,
      });
    },
    reproductionStatus(val) {
      setStore({
        name: "reproductionStatus",
        content: val,
      });
    },
    breedType: function (val) {
      this.breedType = val;
      const d = new Date();
      // this.dateOfBirth = ''
      this.reproductionStatus = "";
      this.currentDate = ref(
        new Date(1900 + d.getYear(), d.getMonth(), d.getDate())
      );
      this.show = false;
      if (this.breedType) {
        this.dateOfBirthDis = true;
        try {
          this.breedName = this.breedTypeList.filter(
            (item) => item.value === this.breedType
          )[0].text;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.dateOfBirthDis = false;
        removeStore({
          name: "breedName",
        });
        removeStore({
          name: "breedType",
        });
      }
      this.breedTypeList.forEach((item) => {
        if (item.value === this.breedType) {
          const breedName = item.label;
          setStore({
            name: "breedName",
            content: breedName,
          });
        }
      });
      setStore({
        name: "breedType",
        content: this.breedType,
      });
      setStore({
        name: "breedCode",
        content: this.breedType,
      });
      if (val && getStore({ name: "dateOfBirth" })) {
        this.queryPetProfile(getStore({ name: "dateOfBirth" }));
      }
    },
  },
  mounted() {
    this.getDictByTypeAndPet(
      "varieties_pet,disease_type,susceptibility,life_stage,amount_of_exercise",
      this.species
    );

    this.queryPetProfile(this.dateOfBirth);
  },
  methods: {
    formatDate(date) {
      return `${1900 + date.getYear()}/${
        date.getMonth() + 1
      }/${date.getDate()}`;
    },
    closePets(pet) {
      this.showPets = false;
      if (!pet.petName) {
        return;
      }
      this.species = pet.petType;
      this.petName = pet.petName;
      this.sex = pet.petGender === 0 ? true : false;
      this.neutered = pet.isSterilization;
      this.data.BCS = pet.bcs === 0 ? 5 : pet.bcs;
      this.currentWeight = pet.weight;
      this.breedCode = pet.varietiesPet;
      this.breedType = pet.varietiesPet;
      // setStore({
      //     name: 'breedType',
      //     content: pet._rawValue.varietiesPet
      // })
      // const formatDate = (date) => `${1900+date.getYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      this.dateOfBirth = this.formatDate(new Date(pet.petBirthday));
    },
    getPets() {
      console.log("getPets=>", this.showBackPets);
      this.showBackPets = true;
      this.showPets = true;
      // if (this.telephone && this.telephone.length === 11) {

      // } else {
      //     this.$dialog.alert({
      //         message: "请输入正确的用户手机号"
      //     })
      // }
    },
    removePathologie(item) {
      let index = this.selectPathologieNames.findIndex((i) => {
        if (i === item) {
          return true;
        }
      });
      this.selectPathologieNames.splice(index, 1);
      setStore({
        name: "selectPathologieNames",
        content: this.selectPathologieNames,
      });
      this.pathologies = [];
      this.sensitivity = [];
      this.pathologiesNames = [];
      this.sensitivityNames = [];
      this.selectPathologieNames.forEach((item) => {
        if (item.listClass === "0") {
          this.pathologies.push(item.dictValue);
          this.pathologiesNames.push(item.dictLabel);
        } else if (item.listClass === "1") {
          this.sensitivity.push(item.dictValue);
          this.sensitivityNames.push(item.dictLabel);
        }
      });
      setStore({
        name: "pathologies",
        content: this.pathologies,
      });
      setStore({
        name: "sensitivity",
        content: this.sensitivity,
      });
      setStore({
        name: "pathologiesName",
        content: this.pathologiesNames,
      });
      setStore({
        name: "sensitivitiesName",
        content: this.sensitivityNames,
      });
    },
    closePathologies() {
      this.showPathologies = false;
      this.selectPathologieNames = getStore({
        name: "selectPathologieNames",
      });
      this.pathologies = [];
      this.sensitivity = [];
      this.pathologiesNames = [];
      this.sensitivityNames = [];
      this.selectPathologieNames.forEach((item) => {
        if (item.listClass === "0") {
          this.pathologies.push(item.dictValue);
          this.pathologiesNames.push(item.dictLabel);
        } else if (item.listClass === "1") {
          this.sensitivity.push(item.dictValue);
          this.sensitivityNames.push(item.dictLabel);
        }
      });
      setStore({
        name: "pathologies",
        content: this.pathologies,
      });
      setStore({
        name: "sensitivity",
        content: this.sensitivity,
      });
      setStore({
        name: "pathologiesName",
        content: this.pathologiesNames,
      });
      setStore({
        name: "sensitivitiesName",
        content: this.sensitivityNames,
      });
    },
    choosePathologies() {
      this.showPathologies = true;
    },
    setBCS(value) {
      this.data.BCS = value;
      setStore({
        name: "bcs",
        content: this.data.BCS,
      });
      if (this.currentWeight) {
        if (value === 1) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = ref();
          }
          this.bcsPathologies = "underweight_bcs_1_(out_of_9)";
          this.bcsPathologiesName = "体重过轻，体况评估1/9分";
        } else if (value === 2) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = ref();
          }
          this.bcsPathologies = "underweight_bcs_2_(out_of_9)";
          this.bcsPathologiesName = "体重过轻，体况评估2/9分";
        } else if (value === 3) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = ref();
          }
          this.bcsPathologies = "underweight_bcs_3_(out_of_9)";
          this.bcsPathologiesName = "体重过轻，体况评估3/9分";
        } else if (value == 4 || value == 5) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = this.currentWeight;
          }
          this.bcsPathologies = "";
          this.bcsPathologiesName = "";
        } else if (value === 6) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = (this.currentWeight / 1.1).toFixed(2);
          }
          this.bcsPathologies = "overweight_bcs_6_(out_of_9)";
          this.bcsPathologiesName = "肥胖，体况评估6/9分";
        } else if (value === 7) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = (this.currentWeight / 1.2).toFixed(2);
          }
          this.bcsPathologies = "obesity_bcs_7_(out_of_9)";
          this.bcsPathologiesName = "肥胖，体况评估7/9分";
        } else if (value === 8) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = (this.currentWeight / 1.3).toFixed(2);
          }
          this.bcsPathologies = "obesity_bcs_8_(out_of_9)";
          this.bcsPathologiesName = "肥胖，体况评估8/9分";
        } else if (value === 9) {
          if (!this.hopeWeightDis) {
            this.hopeWeight = (this.currentWeight / 1.4).toFixed(2);
          }
          this.bcsPathologies = "obesity_bcs_9_(out_of_9)";
          this.bcsPathologiesName = "肥胖，体况评估9/9分";
        }
        setStore({
          name: "bcsPathologies",
          content: this.bcsPathologies,
        });
        setStore({
          name: "bcsPathologiesName",
          content: this.bcsPathologiesName,
        });
      }

      setStore({
        name: "hopeWeight",
        content: this.hopeWeight,
      });
    },
    submitForm(url, type) {
      setStore({
        name: "feature",
        content: type === 0 ? "SR" : "DA",
      });
      removeStore({
        name: "wetProductId",
      });
      removeStore({
        name: "wetProductName",
      });
      removeStore({
        name: "wetProductImage",
      });
      removeStore({
        name: "dryProductId",
      });
      removeStore({
        name: "dryProductName",
      });
      removeStore({
        name: "dryProductImage",
      });
      // if (!this.telephone || this.telephone.length !== 11) {
      //     this.$dialog.alert({
      //         message: "请填写宠主手机号"
      //     })
      //     return
      // }
      if (!this.species) {
        this.$dialog.alert({
          message: "请选择宠物类型",
        });
        return;
      }
      if (!this.petName) {
        this.$dialog.alert({
          message: "请填写宠物名称",
        });
        return;
      }
      if (!this.breedType) {
        this.$dialog.alert({
          message: "请选择宠物品种",
        });
        return;
      }
      if (!this.dateOfBirth) {
        this.$dialog.alert({
          message: "生日不能为空",
        });
        return;
      }
      if (!this.lifeStage && !this.lifeStageDis) {
        this.$dialog.alert({
          message: "运动量不能为空",
        });
        return;
      }
      if (!this.currentWeight) {
        this.$dialog.alert({
          message: "请填写宠物体重信息",
        });
        return;
      }
      if (!this.checked) {
        this.$dialog.alert({
          message: "请选择是否同意保存宠物数据",
        });
        return;
      }
      setStore({
        name: "checked",
        content: true,
      });
      if (!this.checked2) {
        this.$dialog.alert({
          message: "请选择是否同意协议",
        });
        return;
      }
      setStore({
        name: "checked2",
        content: true,
      });

      setStore({
        name: "sex",
        content: this.sex,
      });
      setStore({
        name: "petBreed",
        content: this.species == "cat" ? 1 : 0,
      });
      setStore({
        name: "speciesCode",
        content: this.species,
      });
      setStore({
        name: "species",
        content: this.species,
      });
      setStore({
        name: "genderCode",
        content: this.sex ? "female" : "male",
      });
      setStore({
        name: "neutered",
        content: this.neutered,
      });
      this.insertLog(type, url);

      setStore({
        name: "petActivityCode",
        content: "low",
      });
    },
    insertLog(type, url) {
      console.log(
        "sensitivity=>",
        getStore({
          name: "sensitivity",
        })
      );
      const params = {
        shopId: getStore({
          name: "shopId",
        }),
        id: getStore({
          name: "logId",
        }),
        diseaseType: getStore({
          name: "pathologies",
        })
          ? getStore({
              name: "pathologies",
            }).join(",")
          : "",
        petType: getStore({
          name: "speciesCode",
        }),
        petGender: getStore({
          name: "sex",
        })
          ? 0
          : 1,
        varietiesPet: getStore({
          name: "breedType",
        }),
        petBirthday: getStore({
          name: "dateOfBirth",
        }),
        petName: getStore({
          name: "petName",
        }),
        isSterilization: getStore({
          name: "neutered",
        }),
        susceptibility: getStore({
          name: "sensitivity",
        })
          ? getStore({
              name: "sensitivity",
            }).join(",")
          : "",
        contactWay:
          this.telephone ||
          getStore({
            name: "userPhoneNumber",
          }),
        bcs: getStore({
          name: "bcs",
        }),
        weight: getStore({
          name: "weight",
        }),
        amountOfExercise: getStore({
          name: "lifeStage",
        }),
        hospital: getStore({
          name: "hospital",
        }),
        city:
          getStore({
            name: "city",
          }) === "市辖区"
            ? getStore({
                name: "province",
              })
            : getStore({
                name: "city",
              }),
        hospitalPhone: getStore({
          name: "hospitalPhone",
        }),
        province:
          getStore({
            name: "province",
          }) +
          getStore({
            name: "city",
          }) +
          getStore({
            name: "district",
          }),
        district: getStore({
          name: "district",
        }),
        businessType: 3,
        operType: type,
        criteriaType: getStore({ name: "bcsPathologies" }),
      };
      fetch(process.env.VUE_APP_BASE_API + "smartreco/api/insertOper", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.text())
        .then((result) => {
          console.log("thirdPartyId=>", JSON.parse(result));
          if (JSON.parse(result).code) {
            setStore({
              name: "logId",
              content: JSON.parse(result).data.logId,
            });

            setStore({
              name: "thirdPartyId",
              content: JSON.parse(result).data.thirdPartyId,
            });
          } else {
            console.log(JSON.parse(result).code);
          }

          this.$router.push({
            name: url,
            query: { t: Date.now() },
          });
        })
        .catch((error) => console.log("error", error));
    },
    queryHospital() {
      const mdmCode = getStore({
        name: "mdmCode",
      });

      fetch(
        process.env.VUE_APP_BASE_API +
          "smartreco/api/getHospitalByMdMNo?mdmNo=" +
          mdmCode,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.text())
        .then((result) => {
          console.log("获取医院信息状态码：", JSON.parse(result).code);

          setStore({
            name: "shopId",
            content: JSON.parse(result).data.shopId,
          });
          setStore({
            name: "shopName",
            content: JSON.parse(result).data.name,
          });
          setStore({
            name: "location",
            content: JSON.parse(result).data.address,
          });
          setStore({
            name: "contactPhone",
            content: JSON.parse(result).data.phone,
          });
        })
        .catch((error) => console.log("error", error));
    },
    getDictByTypeAndDogPet(dictType, petType) {
      fetch(
        process.env.VUE_APP_BASE_API +
          "smartreco/api/getDictByTypeAndPet?dictType=" +
          dictType +
          "&petType=" +
          petType
      )
        .then((response) => response.text())
        .then((result) => {
          this.globalData = JSON.parse(result);
          if (this.globalData.code) {
            this.breedTypeDogList = [];
            this.globalData.data.varieties_pet.forEach((element) => {
              this.breedTypeDogList.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });
          } else {
            console.log(this.globalData.code);
          }
        })
        .catch((error) => console.log("error", error));
    },
    getDictByTypeAndCatPet(dictType, petType) {
      fetch(
        process.env.VUE_APP_BASE_API +
          "smartreco/api/getDictByTypeAndPet?dictType=" +
          dictType +
          "&petType=" +
          petType
      )
        .then((response) => response.text())
        .then((result) => {
          this.globalData = JSON.parse(result);
          if (this.globalData.code) {
            this.breedTypeCatList = [];
            this.globalData.data.varieties_pet.forEach((element) => {
              this.breedTypeCatList.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });
          } else {
            console.log(this.globalData.code);
          }
        })
        .catch((error) => console.log("error", error));
    },
    getDictByTypeAndPet(dictType, petType) {
      Toast.loading({
        message: "宠物品种加载中...", //加载的时候显示的文字
        duration: 3000, //展示时长(ms)，值为 0 时，toast 不会消失
        overlay: true, //是否显示遮罩层
        forbidClick: true, //是否禁止背景点击
      });
      fetch(
        process.env.VUE_APP_BASE_API +
          "smartreco/api/getDictByTypeAndPet?dictType=" +
          dictType +
          "&petType=" +
          petType
      )
        .then((response) => response.text())
        .then((result) => {
          this.globalData = JSON.parse(result);
          if (this.globalData.code) {
            this.breedTypeList = [];
            this.pathologiesList = [];
            this.sensitivitiesList = [];
            this.values = [];
            this.globalData.data.varieties_pet.forEach((element) => {
              this.breedTypeList.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });

            this.globalData.data.disease_type.forEach((element) => {
              this.pathologiesList.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });
            this.globalData.data.susceptibility.forEach((element) => {
              this.sensitivitiesList.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });
            this.globalData.data.life_stage.forEach((element) => {
              this.lifeStages.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });
            this.globalData.data.amount_of_exercise.forEach((element) => {
              this.values.push({
                label: element.dictLabel,
                value: element.dictValue,
              });
            });
          } else {
            console.log(this.globalData.code);
          }
          // toast.clear()
        })
        .catch((error) => console.log("error", error));
    },
    datedifference(sDate1, sDate2) {
      //sDate1和sDate2是2006-12-18格式
      let dateSpan, iDays;
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
    queryPetProfile(newDate) {
      if (this.breedType && this.dateOfBirth && this.species) {
        const params = {
          speciesCode: this.species,
          breedCode: this.breedType,
          genderCode: this.sex == true ? "female" : "male",
          dateOfBirth: new Date(newDate).Format("yyyy-MM-dd"),
          logId: getStore({
            name: "shopId",
          }),
        };
        const toast = Toast.loading({
          message: "获取宠物年龄阶段...", //加载的时候显示的文字
          duration: 0, //展示时长(ms)，值为 0 时，toast 不会消失
          overlay: true, //是否显示遮罩层
          forbidClick: true, //是否禁止背景点击
        });
        fetch(process.env.VUE_APP_BASE_API + "smartreco/api/petProfile", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        })
          .then((response) => response.text())
          .then((result) => {
            toast.clear();
            if (!JSON.parse(result).code) {
              const petType = JSON.parse(result);
              this.hopeWeight = this.hopeWeight
                ? this.hopeWeight
                : petType.adultTargetWeight.measure;
              console.log(
                "newDate=>",
                this.datedifference(new Date(newDate), new Date())
              );
              if (this.datedifference(new Date(newDate), new Date()) < 30) {
                this.$dialog.alert({
                  message:
                    "您输入宠物的年龄小于1月龄，母乳喂养是目前最佳的营养方案",
                });
                this.dateOfBirth = "";
                return;
              }

              if (
                petType.lifestage === "puppy" ||
                petType.lifestage === "kitten" ||
                petType.lifestage === "junior"
              ) {
                this.hopeWeightDis = true;
                this.lifeStageDis = true;
                this.lifeStage = ref("0");
                this.reproductionStatusDis = true;
                this.hopeWeight = petType.adultTargetWeight.measure;
              } else if (petType.lifestage === "adult") {
                if (this.currentWeight) {
                  this.adultHopeWeightDis = true;
                } else {
                  this.adultHopeWeightDis = false;
                }
                this.hopeWeightDis = false;
                if (this.sex) {
                  this.reproductionStatusDis = true;
                } else {
                  this.reproductionStatusDis = false;
                }
                this.lifeStageDis = false;
              } else if (petType.lifestage === "not available") {
                this.$dialog
                  .confirm({
                    title: "异常提示",
                    message: "生日超出宠物最大年龄限制，请重新选择！",
                    showCancelButton: false, // 取消
                  })
                  .then(async () => {
                    // 点击确认按钮后的调用
                    console.log("点击了确认按钮噢");
                    this.dateOfBirth = "";
                  });
                this.hopeWeightDis = false;
                if (this.sex) {
                  this.reproductionStatusDis = true;
                } else {
                  this.reproductionStatusDis = false;
                }
                this.lifeStageDis = false;
              } else {
                this.hopeWeightDis = false;
                if (this.sex) {
                  this.reproductionStatusDis = true;
                } else {
                  this.reproductionStatusDis = false;
                }
                this.lifeStageDis = false;
              }
              setStore({
                name: "life",
                content: petType.lifestage,
              });
              this.lifeStages.forEach((item) => {
                if (item.value === petType.lifestage) {
                  this.lifeStageName = item.label;
                  setStore({
                    name: "lifeStageName",
                    content: item.label,
                  });
                }
              });
            } else {
              this.$dialog
                .confirm({
                  title: "异常提示",
                  message: "获取宠物品种信息异常，请确认是否重试",
                  showCancelButton: true, // 取消
                })
                .then(async () => {
                  // 点击确认按钮后的调用
                  console.log("点击了确认按钮噢");
                  this.queryPetProfile(this.dateOfBirth);
                });
              // .catch(() => { // 点击取消按钮后的调用
              // console.log('点击了取消按钮噢')
              // })
              // Toast.loading({
              //     message: JSON.parse(result).msg === 'timeout' ? 'API请求超时，请重试' : JSON.parse(result).msg, //加载的时候显示的文字
              //     duration: 3000, //展示时长(ms)，值为 0 时，toast 不会消失
              //     overlay: true, //是否显示遮罩层
              //     forbidClick: true //是否禁止背景点击
              // });
              console.log(JSON.parse(result).code);
            }
          })
          .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style><style scoped>
.bg-theme {
  background-color: #e62122;
}

.border-theme {
  border-color: #e62122;
}

.text-theme {
  color: #e62122;
}
</style><style lang="less" scoped>
:deep(.mb-2) {
  margin-bottom: 0.25rem;
  font-size: 16px;
  color: #768082;
}

.home {
  padding: 30px;

  :deep(.van-calendar__confirm) {
    color: #e62122;
  }
}

.van-dropdown-menu {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-bottom: 0.25rem;

  :deep(.van-dropdown-menu__bar) {
    position: relative;
    display: flex;
    height: 45px;
    background: var(--van-dropdown-menu-background-color);
    box-shadow: var(--van-dropdown-menu-box-shadow);
    border-radius: 5px;
  }
}

.input-item {
  padding: 0;
  margin-bottom: 0.25rem;

  :deep(.van-field__body) {
    padding-left: 5px;
    background: #ffffff;
    border: 1.6px solid #d4d6db;
    border-radius: 5px;
    height: 48px;
    height: 40px;
    font-size: 14px;
    color: #909399;
    letter-spacing: 0;
    // line-height: 30px;
    // font-weight: 400;
  }
}

.bcs-input-item {
  margin-bottom: 0.25rem;
  display: inline-block;
  font-family: FZLTHJW--GB1-0;
  font-size: 20px;
  color: #909399;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 400;

  h3 {
    font-family: FZLTZHJW--GB1-0;
    font-size: 18px;
    color: #333333;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  > input {
    background: #ffffff;
    border: 1.6px solid #d4d6db;
    border-radius: 6px;
    display: block;
    padding: 10px 6px;
    width: 100%;

    background: #ffffff;
    border: 1.6px solid #d4d6db;
    border-radius: 6px;
  }

  .radios {
    display: flex;
    justify-content: space-between;

    button {
      background: #ffffff;
      border-radius: 6px;
      padding: 10px 25px;
    }

    .button-active {
      background: #e62122;
      border: none;
      color: white;
    }
  }
}

.multiselect {
  align-items: center;
  background: var(--ms-bg, #fff);
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: var(--ms-font-size, 1rem);
  justify-content: flex-end;
  margin: 0 auto;
  min-height: calc(
    var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) *
      var(--ms-line-height, 1.375) + var(--ms-py, 0.5rem) * 2
  );
  outline: none;
  position: relative;
  width: 100%;
  height: 45px;
}

.rounded-rectangle-27-copy {
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  margin-bottom: 0.25rem;
}

.add {
  width: 28px;
  height: 28px;
  background-blend-mode: normal, normal;
  margin-left: 46%;
  margin-top: 8px;
}

.rounded-rectangle-27-copy-2-4-active {
  background-color: #ffffff;
  float: left;
  padding: 10px;
  margin: 0px 10px 10px 0px;
  border-radius: var(--ms-radius, 4px);
}
</style>
