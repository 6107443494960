<template>
  <router-view/>
</template>
<script>
export default {
  created() {
    // 禁止页面缩放
    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };
  },
}
</script>
<style>
body{
}
</style>
