/*
 * @description: Don't description
 * @Author: RoyalCannin
 * @Date: 2022-06-05 21:42:35
 * @FilePath: \reco-uif:\source code\EVET\smart-reco-h5\src\router\index.js
 * @LastEditTime: 2022-08-05 01:34:33
 */
import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/step1",
    name: "step",
    component: () => import("../views/Step1.vue"),
  },
  {
    path: "/Step2",
    name: "Step2",
    component: () => import("../views/Step2.vue"),
  },
  {
    path: "/Step3",
    name: "Step3",
    component: () => import("../views/Step3.vue"),
  },
//   {
//     path: "/pdf",
//     name: "PdfView",
//     component: () => import("../views/Pdf.vue"),
//   },
  {
    path: "/StepView2",
    name: "StepView2",
    component: () => import("../views/StepView2.vue"),
  },
  {
    path: "/finish",
    name: "finish",
    component: () => import("../views/FinishedPage"),
  },
  {
    path: "/pdf",
    name: "hospital",
    component: () => import("../views/PetHospital/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
