<!--
 * @description: Don't description
 * @Author: RoyalCannin
 * @Date: 2022-06-05 21:42:34
 * @FilePath: \smart-reco-xn\src\components\SelectPathologies.vue
 * @LastEditTime: 2024-01-31 20:43:33
-->
<template>
<div class="dark:bg-white">
    <input type="checkbox" :checked="true" id="my-modal-3" class="modal-toggle" />
    <div class="modal bg-white dark:bg-white">
        <div class="modal-box relative rounded-md bg-white dark:bg-white">
            <button @click="close" class="btn btn-sm btn-circle absolute right-2 border-none top-2 bg-white text-black">✕</button>
            <van-row style="font-size: 36px;color: #E62122;" class="mb-4">
                疾病类型&易感性
            </van-row>
            <van-collapse v-model="activeNames">
                <van-collapse-item :icon="category.icon" v-for="(category,index) in pathologiesList" :name="(index+1)" v-bind:key="category">
                    <template #title>
                        <div class="category" style="line-height:30px;margin-left: 10px;">{{category.dictLabel}}
                        </div>
                    </template>
                    <div v-for="item in category.children" v-bind:key="item.id">
                        <van-tag v-if="item.isActive" @click="setPathologies(item)" class="rounded-rectangle-27-copy-2-4-active" color="#E62122" plain>{{item.dictLabel}}</van-tag>
                        <van-tag v-else-if="item.listClass=='0'" @click="setPathologies(item)" class="rounded-rectangle-27-copy-2-4" color="#6fa7ac" plain>{{item.dictLabel}}</van-tag>
                        <van-tag v-else-if="item.listClass=='1'" @click="setPathologies(item)" class="rounded-rectangle-27-copy-2-4" color="#7e89a9" plain>{{item.dictLabel}}</van-tag>
                    </div>
                </van-collapse-item>

            </van-collapse>
            <van-row style="display: flex;align-items: center;justify-content: center;">
                <van-col offset="1" span="10" class="mt-4">
                    <button @click="close" class="btn border-none" style="background: #E62122;border-radius: 6px;">确认选择</button>
                </van-col>
            </van-row>
        </div>
    </div>
</div>
</template>

<script>
import {
    Toast
} from 'vant'
import {
    ref,
    reactive
} from "vue";
import {
    getStore,
    setStore
} from '@/utils/store';
export default {
    name: "SelectPathologies",
    props: {
        species: {
            type: Object,
            require: true
        }
    },
    emits: ['close'],
    setup(_, {
        emit
    }) {
        const selectPathologiesNameList = reactive(getStore({
            name: 'selectPathologieNames'
        }) || []);
        const pathologiesList = ref([]);
        const close = () => emit('close');
        const show = ref(true);
        return {
            show,
            pathologiesList,
            selectPathologiesNameList,
            close,
        }
    },
    data() {
        return {
            activeNames: []
        }
    },
    mounted() {
        console.log("this.selectPathologiesNameList", this.selectPathologiesNameList)
        this.getDictByTypeAndPet("illness_classify", this.species || 'all');
    },
    methods: {
        setPathologies(itemDict) {
            for (const item in this.selectPathologiesNameList) {
                if (this.selectPathologiesNameList[item].id === itemDict.id) {
                    this.selectPathologiesNameList.splice(item, 1)
                    setStore({
                        name: "selectPathologieNames",
                        content: this.selectPathologiesNameList
                    })

                    this.pathologiesList.forEach(categroy => {
                        categroy.children.forEach(item => {
                            item.isActive = false;
                            this.selectPathologiesNameList.forEach(pathologiesItem => {
                                if (item.dictValue.replace(/\s/g, "") === pathologiesItem.dictValue.replace(/\s/g, "")) {
                                    item.isActive = true;
                                }
                            })
                        })
                    })
                    return;
                }
            }
            this.selectPathologiesNameList.push(itemDict)
            setStore({
                name: "selectPathologieNames",
                content: this.selectPathologiesNameList
            })
            this.pathologiesList.forEach(categroy => {
                categroy.children.forEach(item => {
                    this.selectPathologiesNameList.forEach(pathologiesItem => {
                        if (item.dictValue.replace(/\s/g, "") === pathologiesItem.dictValue.replace(/\s/g, "")) {
                            item.isActive = true;
                        }
                    })
                })
            })
        },
        getDictByTypeAndPet(dictType, petType) {
            const toast = Toast.loading({
                message: '疾病类型&易感性加载中...', //加载的时候显示的文字
                duration: 0, //展示时长(ms)，值为 0 时，toast 不会消失
                overlay: true, //是否显示遮罩层
                forbidClick: true //是否禁止背景点击
            });
            fetch(process.env.VUE_APP_BASE_API+"smartreco/api/queryTreeDict?dictType=" + dictType + "&petType=" + petType, )
                .then(response => response.text())
                .then(result => {
                    toast.clear()
                    this.globalData = JSON.parse(result);
                    if (this.globalData.code) {
                        this.pathologiesList = this.globalData.data;
                        this.pathologiesList.forEach(categroy => {
                            categroy.icon = 'https://ecadmin.royalcanin.com.cn/static/' + categroy.dictValue + '.svg';
                            categroy.children.forEach(item => {
                                this.selectPathologiesNameList.forEach(pathologiesItem => {
                                    if (item.dictValue.replace(/\s/g, "") === pathologiesItem.dictValue.replace(/\s/g, "")) {
                                        item.isActive = true;
                                    }
                                })
                            })
                        })
                    } else {
                        console.log(this.globalData.code)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
}
</script>

<style lang="less" scoped>
.mask {
    background-color: lightgrey;
    z-index: 10;
}

.category {
    font-size: 16px;
    color: #808285;
}

:deep(.van-icon__image) {
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
    // margin-top: 15%;
    font-size: 30px;
    border: 2.6px solid #D4D6DB;
    border-radius: 30px;
}

.rounded-rectangle-27-copy-2-4 {
    background-color: #ffffff;
    float: left;
    padding: 10px;
    margin: 0px 10px 10px 0px;
    border-radius: var(--ms-radius, 4px);
}

.rounded-rectangle-27-copy-2-4-active {
    background-color: #ffffff;
    float: left;
    padding: 10px;
    margin: 0px 10px 10px 0px;
    border-radius: var(--ms-radius, 4px);
}

.van-cell {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    overflow: hidden;
    color: var(--van-cell-text-color);
    font-size: var(--van-cell-font-size);

    /* line-height: var(--van-cell-line-height); */
    background: var(--van-cell-background-color);
}
</style>
